<template>
  <b-card
    title="Acquire BCA tokens with COP in our P2P marketplace"
  >
    <b-card-header style="padding: 0.5rem 1.5rem;">
      <b-media no-body>
        <b-media-body />
      </b-media>
      <b-button
        v-if="usrData.data.invitation.wallet=='0xeea88bf5c50c4cf11b0401c9dfde5e74ca5ac38d'"
        style="width:30px; padding: 0"
        alt="History P2P Orders"
        title="History P2P Orders"
        @click="showOrderListSeller"
      >
        <feather-icon
          icon="ClockIcon"
          size="30"
        />
      </b-button>
      <div style="text-align: center;">
        <b-button
          style="width:30px; padding: 0;"
          alt="History P2P Orders"
          title="History P2P Orders"
          @click="showOrderListBuyer"
        >
          <feather-icon
            icon="ClockIcon"
            size="30"
          />
        </b-button>
        <br>
        Pending Orders
      </div>
    </b-card-header>
    <b-card-body>
      <!-- table -->
      <b-table
        :fields="fields"
        :items="items"
        responsive
        bordered
        show-empty
        class="mb-0"
        style="max-height:200px; overflow-y:'scroll'"
      >

        <!-- A custom formatted column -->
        <template #cell(consecutive)="data">
          <b-button
            variant="outline-secondary"
            class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
            style="background: #a6ce3f; color: black;"
            @click="showModalDetail(data.value, data.item.copAmmount)"
          >
            BUY
            <img
              style="width:15px"
              :src="require('@/assets/images/token/coin-logo.png')"
            >
          </b-button>
        </template>

        <!-- A virtual composite column -->
        <template #cell(copAmmount)="data">
          {{ 'COP '+(data.value/10**18).toFixed(2) }}
        </template>

        <!-- A virtual composite column -->
        <template #cell(bcaAmmount)="data">
          {{ (data.value/10**18).toFixed(2)+ ' BCA' }}
        </template>

        <template #cell(bank)="data">
          <b-badge
            pill
            variant="success"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
    </b-card-body>

    <!-- modal detalle de la reserva -->
    <b-modal
      ref="p2pmodal"
      ok-only
      ok-title="Close"
      hide-footer
      no-close-on-backdrop
      centered
      size="x2"
      title="P2P Detail"
    >
      <b-card
        no-body
        class="card-apply-job"
      >
        <b-card-header class="pb-1">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <img
                style="width:20px"
                :src="require('@/assets/images/token/coin-logo.png')"
              >
            </b-media-aside>
            <b-media-body>
              <h5 class="mb-0">
                Exchange order Detail
              </h5>
              <small class="text-muted">Reserved by 30m</small>
            </b-media-body>
          </b-media>
          <b-badge
            pill
            variant="light-primary"
          >
            COP/BCA
          </b-badge>
        </b-card-header>
        <b-card-body>
          <h5 class="apply-job-title">
            You have this order reserved, continue by following these steps:
          </h5><br>
          <b-card-text class="mb-2">
            <b>1.</b> Make the deposit to the bank account as provided in the information below.<br>
            <b>2.</b> Confirm your deposit using the '<b>Confirm payment sent</b>' button.<br>
            <b>3.</b> Please wait a few minutes while your BCAs are loaded into your Bancannabis wallet.<br>
          </b-card-text>

          <!-- bank data  -->
          <div class="apply-job-package bg-light-primary rounded">
            <div>
              <sup class="text-body">
                <small>ACOUNT</small>
              </sup>
              <h2 class="d-inline mr-25">
                350 372 9370
              </h2>
            </div>
            <b-badge
              variant="light-primary"
              pill
            >
              Nequi
            </b-badge>
          </div>
          <!--/ payment  -->
          <!-- payment  -->
          <div class="apply-job-package bg-light-primary rounded">
            <div>
              <sup class="text-body">
                <small>COP</small>
              </sup>
              <h2 class="d-inline mr-25">
                {{ (currentCopAmount/10**18).toFixed(2) }}
              </h2>
              <sub class="text-body"><small> - Total to deposit</small></sub>
            </div>
          </div>
          <!--/ payment  -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :disabled="paymentConfirmed==1"
            @click="confirmDeposit"
          >
            Confirm payment sent
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="secundary"
            @click="closeP2pModal"
          >
            Close
          </b-button>
        </b-card-body>
      </b-card>
    </b-modal>

    <!-- modal listado ordenes buyer -->
    <b-modal
      id="modal-p2p-orderlist-buyer"
      ref="p2porderlistmodal"
      ok-only
      ok-title="Close"
      no-close-on-backdrop
      centered
      size="lg"
      title="P2P Orders list"
    >
      <b-card
        no-body
        class="card-apply-job"
      >
        <b-card-header class="pb-1">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <img
                style="width:20px"
                :src="require('@/assets/images/token/coin-logo.png')"
              >
            </b-media-aside>
            <b-media-body>
              <h5 class="mb-0">
                P2P Order Lists
              </h5>
              <small class="text-muted">My orders</small>
            </b-media-body>
          </b-media>
          <b-badge
            pill
            variant="light-primary"
          >
            COP/BCA
          </b-badge>
        </b-card-header>
        <b-card-body>

          <b-table
            :fields="historyFields"
            :items="historyItems"
            responsive
            bordered
            show-empty
            class="mb-0"
            style="max-height:200px; overflow-y:'scroll'"
          >

            <!-- A custom formatted column -->
            <template #cell(consecutive)="data">
              <b-button
                v-if="data.item.buyerValidation"
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                style="background: #a6ce3f; color: black;"
                @click="scrowScaling(data.value)"
              >
                Escalate BCA Agent
              </b-button>
              <b-button
                v-if="!data.item.buyerValidation"
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                style="background: #a6ce3f; color: black;"
                @click="historyConfirmDeposit(data.value)"
              >
                Confirm Deposit
              </b-button>
            </template>

            <!-- A virtual composite column -->
            <template #cell(copAmmount)="data">
              {{ 'COP '+(data.value/10**18) }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(bcaAmmount)="data">
              {{ (data.value/10**18)+ ' BCA' }}
            </template>

            <template #cell(status)="data">
              <b-badge
                pill
                variant="success"
              >
                {{ data.value }}
              </b-badge>
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>

          <!--/ payment  -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="closeOrderListBuyer"
          >
            Close
          </b-button> -->
        </b-card-body>
      </b-card>
    </b-modal>

    <b-modal
      id="modal-p2p-orderlist-seller"
      ref="p2porderlistmodalseller"
      ok-only
      ok-title="Close"
      no-close-on-backdrop
      centered
      size="lg"
      title="P2P Orders list"
    >
      <b-card
        no-body
        class="card-apply-job"
      >
        <b-card-header class="pb-1">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <img
                style="width:20px"
                :src="require('@/assets/images/token/coin-logo.png')"
              >
            </b-media-aside>
            <b-media-body>
              <h5 class="mb-0">
                P2P Order Seller Lists
              </h5>
              <small class="text-muted">My orders</small>
            </b-media-body>
          </b-media>
          <b-badge
            pill
            variant="light-primary"
          >
            COP/BCA
          </b-badge>
        </b-card-header>
        <b-card-body>

          <b-table
            :fields="historyFieldsSeller"
            :items="historyItemsSeller"
            responsive
            bordered
            show-empty
            class="mb-0"
            style="max-height:200px; overflow-y:'scroll'"
          >

            <!-- A custom formatted column -->
            <!-- Si el comprador ya confirmo, entonces mostrar liberar -->
            <template #cell(consecutive)="data">
              <b-button
                v-if="data.item.buyerValidation"
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                style="background: #a6ce3f; color: black;"
                :disabled="confirmingSeller==1"
                @click="confirmSeller(data.item.consecutive)"
              >
                Confirm Buyer Deposit
              </b-button>
              <!-- <b-button
                v-else
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                style="background: #a6ce3f; color: black;"
                @click="cancelReserve(data.item.orderId)"
              >
                Cancel Reserve
              </b-button> -->
            </template>

            <!-- A virtual composite column -->
            <template #cell(copAmmount)="data">
              {{ 'COP '+(data.value/10**18) }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(bcaAmmount)="data">
              {{ (data.value/10**18).toFixed(2)+ ' BCA' }}
            </template>

            <template #cell(status)="data">
              <b-badge
                pill
                variant="success"
              >
                {{ data.value }}
              </b-badge>
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>

          <!--/ payment  -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="closeOrderListSeller"
          >
            Close
          </b-button> -->
        </b-card-body>
      </b-card>
    </b-modal>
    <!-- loading modal -->
    <b-modal
      id="modal-p2p-loading"
      centered
      ok-only
      hide-footer
      ok-disabled
      no-close-on-backdrop
      title="Transaction in progress"
    >
      <b-col>
        <b-progress
          animated
          centered
          value="100"
          variant="success"
          class="'progress-bar-success'"
        />
        <span
          v-show="currentMsg!=''"
          class="text-warning"
        >{{ currentMsg }}</span>
      </b-col>
    </b-modal>

  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  BTable, BCard, BButton, BCol, BBadge, BProgress, VBModal, BModal, BCardHeader, BMedia, BMediaAside, BMediaBody, BCardText, BCardBody,
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  directives: {
    'v-modal': VBModal,
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BButton,
    BBadge,
    BModal,
    BCardHeader,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BCardBody,
    BProgress,
    BCol,
    // BFormGroup,
    // BFormInput,
    // BDropdown,
    // BDropdownItem,
  },
  data() {
    return {
      currentNetworkId: this.$sessionData.getCurrentNetworkData().id,
      currentId: 0,
      currentCopAmount: 0,
      paymentConfirmed: 0,
      confirmingSeller: 0,
      currentMsg: '',
      usrData: this.$sessionData.get(),
      fields: [
        { key: 'copAmmount', label: 'Price COP' },
        { key: 'bcaAmmount', label: 'You get' },
        { key: 'bank', label: 'Bank' },
        { key: 'consecutive', label: 'Action' },
      ],
      items: [],
      historyFields: [
        { key: 'copAmmount', label: 'Price COP' },
        { key: 'bcaAmmount', label: 'You get' },
        { key: 'status', label: 'Order Status' },
        { key: 'consecutive', label: 'Action' },
      ],
      historyItems: [],
      historyFieldsSeller: [
        { key: 'copAmmount', label: 'Price COP' },
        { key: 'bcaAmmount', label: 'You get' },
        { key: 'status', label: 'Order Status' },
        { key: 'consecutive', label: 'Action' },
      ],
      historyItemsSeller: [],
      validateStatusOrderInterval: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Available    : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    const intervalGetOrders = setInterval(() => {
      this.getOrders()
    }, 10000)
    this.getOrders()
    window.addEventListener('beforeunload', clearInterval(this.validateStatusOrderInterval))
    window.addEventListener('beforeunload', clearInterval(intervalGetOrders))
  },
  methods: {
    openModal(id) {
      this.$bvModal.show(id)
    },
    closeModal(id) {
      this.$bvModal.hide(id)
    },
    showLoading() {
      this.openModal('modal-p2p-loading')
    },
    closeLoading() {
      this.closeModal('modal-p2p-loading')
    },
    showOrderListBuyer() {
      this.getBuyerOrders()
      this.openModal('modal-p2p-orderlist-buyer')
    },
    closeOrderListBuyer() {
      this.closeModal('modal-p2p-orderlist-buyer')
    },
    showOrderListSeller() {
      this.getSellerOrders()
      this.openModal('modal-p2p-orderlist-seller')
    },
    closeOrderListSeller() {
      this.closeModal('modal-p2p-orderlist-seller')
    },
    closeModalDetail() {
      this.$refs.p2pmodal.hide()
    },
    // abrir modal
    showModalDetail(orderId, copAmmount) {
      if (!this.$sessionData.validateKyc(ToastificationContent, this.$toast)) return
      this.currentId = orderId
      this.currentCopAmount = copAmmount
      this.paymentConfirmed = 0
      if (window.confirm('Do you wish to reserve the order now?')) {
        this.reserveOrder()
          .then(() => {
            this.$refs.p2pmodal.show()
          })
      }
      // Validar el estado de la orden
      this.validateStatusOrderInterval = setInterval(async () => {
        const state = await this.fetchOrderState()
        if (state.closed) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: 'The order has been released successfully, and now your BCAs are in your wallet.',
              variant: 'success',
            },
          },
          {
            position: 'bottom-center',
            timeout: 4000,
          })
          clearInterval(this.validateStatusOrderInterval)
          this.$router.push({ name: 'wallet' })
        }
      }, 20000)
    },
    // Reservar orden
    reserveOrder() {
      return new Promise((resolve, reject) => {
        this.currentMsg = 'Making a reservation, please wait...'
        this.showLoading()
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        this.$http.post(`/p2p/${this.currentId}/reserve?nid=${this.currentNetworkId}`, {
          wallet: this.$sessionData.get().data.kyc.wallet,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'The order was successfully reserved.',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
              timeout: 2000,
            })
            // Quitamos del listado la orden reservada
            this.items = this.items.filter(item => item.orderId !== this.currentId)
            this.closeLoading()
            this.getOrders()
            resolve()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'The order could not be reserved, please try again or refresh the order list.',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 3000,
            })
            this.currentMsg = ''
            this.closeLoading()
            reject()
          })
      })
    },
    // usuario confirma deposito
    confirmDeposit() {
      if (this.paymentConfirmed === 1) return false
      return new Promise((resolve, reject) => {
        this.currentMsg = 'Confirming deposit, please wait a moment...'
        this.showLoading()
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        this.$http.post(`/p2p/${this.currentId}/confirm?nid=${this.currentNetworkId}`, {
          wallet: this.$sessionData.get().data.kyc.wallet,
          buyerValidation: true,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'The deposit has been confirmed, very soon your BCAs will be reflected in your wallet.',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
              timeout: 4000,
            })
            this.paymentConfirmed = 1
            // this.$refs.p2pmodal.hide()
            this.currentMsg = ''
            this.closeLoading()
            this.closeModalDetail()
            this.closeOrderListBuyer()
            resolve()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'The deposit could not be confirmed, please try again.',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 5000,
            })
            this.currentMsg = ''
            this.closeLoading()
            reject()
          })
      })
    },
    confirmSeller(orderId) {
      if (this.confirmingSeller === 1) return false
      return new Promise((resolve, reject) => {
        this.currentMsg = 'Completing the transaction, please wait a moment...'
        this.showLoading()
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        this.$http.post(`/p2p/${orderId}/sellerconfirm?nid=${this.currentNetworkId}`, {
          wallet: this.$sessionData.get().data.kyc.wallet,
          sellerValidation: true,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'The BCA tokens have been successfully released.',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
              timeout: 4000,
            })
            // this.$refs.p2pmodal.hide()
            this.currentMsg = ''
            this.confirmingSeller = 0
            this.closeLoading()
            this.closeOrderListSeller()
            resolve()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'The transaction could not be completed.',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 5000,
            })
            this.currentMsg = ''
            this.confirmingSeller = 0
            this.closeLoading()
            reject()
          })
      })
    },
    cancelOrder(orderId) {
      if (this.confirmingCancel === 1) return false
      return new Promise((resolve, reject) => {
        this.currentMsg = 'Canceling the transaction, please wait a moment...'
        this.showLoading()
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        this.$http.post(`/p2p/${orderId}/cancel?nid=${this.currentNetworkId}`, {
          wallet: this.$sessionData.get().data.kyc.wallet,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Order is cancel.',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
              timeout: 4000,
            })
            // this.$refs.p2pmodal.hide()
            this.currentMsg = ''
            this.closeLoading()
            resolve()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'The transaction could not be completed.',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 5000,
            })
            this.currentMsg = ''
            this.confirmingSeller = 0
            this.closeLoading()
            reject()
          })
      })
    },
    historyConfirmDeposit(orderId) {
      // debugger
      this.currentId = orderId
      this.paymentConfirmed = 0
      this.$refs.p2pmodal.show()
    },
    closeP2pModal() {
      this.currentId = 0
      this.paymentConfirmed = 0
      this.$refs.p2pmodal.hide()
    },
    // Obtener ordenes
    getOrders() {
      return new Promise((resolve, reject) => {
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        this.$http.get(`/p2p?nid=${this.currentNetworkId}`)
          .then(response => {
            this.items = response.data
            resolve(response.data)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Failed to fetch orders from the P2P market.',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 3000,
            })
            reject()
          })
      })
    },
    getBuyerOrders() {
      return new Promise((resolve, reject) => {
        const sData = this.$sessionData.get()
        this.$http.defaults.headers['x-access-token'] = sData.token
        this.$http.get(`/p2p/${sData.data.invitation.wallet}/buyerorder?nid=${this.currentNetworkId}`)
          .then(response => {
            const pending = response.data.map(order => {
              // eslint-disable-next-line no-underscore-dangle
              const _order = order
              return {
                consecutive: _order.consecutive,
                copAmmount: _order.copAmmount,
                bcaAmmount: _order.bcaAmmount,
                status: order.buyerValidation ? 'Waiting Seller action' : 'Waiting your deposit',
                buyerValidation: order.buyerValidation, // 1 mostrar boton escalar
              }
            })
            this.historyItems = pending
            resolve()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Failed to fetch pending orders from the P2P market.',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 3000,
            })
            reject()
          })
      })
    },
    getSellerOrders() {
      return new Promise((resolve, reject) => {
        const sData = this.$sessionData.get()
        this.$http.defaults.headers['x-access-token'] = sData.token
        this.$http.get(`/p2p/${sData.data.invitation.wallet}/sellerorder?nid=${this.currentNetworkId}`)
          .then(response => {
            // debugger
            const pending2 = response.data.map(order => {
              // eslint-disable-next-line no-underscore-dangle
              const _order = order
              return {
                consecutive: _order.consecutive,
                copAmmount: _order.copAmmount,
                bcaAmmount: _order.bcaAmmount,
                status: order.buyerValidation ? 'Waiting your action' : 'Waiting Buyer deposit',
                buyerValidation: order.buyerValidation, // 1 mostrar boton escalar
              }
            })
            // debugger
            this.historyItemsSeller = pending2
            resolve()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Failed to fetch pending seller orders from the P2P market.',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 3000,
            })
            reject()
          })
      })
    },
    // obtener el estado de la orden actual
    fetchOrderState() {
      return new Promise(resolve => {
        if (this.currentId === 0) resolve()
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        this.$http.get(`/p2p/${this.currentId}/status?nid=${this.currentNetworkId}`)
          .then(response => {
            resolve(response.data)
          })
      })
    },
  },
}
</script>

<style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
